import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaCheckCircle, FaTerminal } from "react-icons/fa";
import { MdCancel, MdOutput } from "react-icons/md";
import Spinner from "../../shared/Spinner";

const DebugOutput = ({ logs }) => {
  const [activeTest, setActiveTest] = useState(null);

  if (!logs) return null;

  const parseLogLine = (line) => {
    const regex =
      /(\d{2}:\d{2}:\d{2}\.\d{2})\s*\(\d+\)\|([^|]+)\|(?:\[(\d+)\]\|)?([^|]*)(?:\|(.*))?/;
    const match = line.match(regex);

    if (!match) return null;

    return {
      timestamp: match[1],
      type: match[2],
      level: match[4],
      message: match[5],
    };
  };

  const buildTestSections = (logs) => {
    const lines = logs.split("\n");
    let currentTest = null;
    const sections = {};

    for (const line of lines) {
      const markerMatch = line.match(
        /\|DEBUG\|----CAMP_APEX_TEST_NO_(\d+)----/
      );

      if (markerMatch) {
        currentTest = markerMatch[1];
        sections[currentTest] = [];
      } else if (
        currentTest &&
        !line.includes("CODE_UNIT") &&
        !line.includes("EXECUTION_STARTED") &&
        !line.includes("EXECUTION_FINISHED") &&
        line.trim()
      ) {
        sections[currentTest].push(line);
      }
    }

    return sections;
  };

  const sections = buildTestSections(logs);
  const testNumbers = Object.keys(sections).sort(
    (a, b) => Number(a) - Number(b)
  );

  const renderLogLine = (log) => (
    <div className="leading-5 mb-1">
      <span className="text-blue-400">{log.timestamp}</span>
      <span className="text-gray-500"> | </span>
      <span
        className={
          log.type.includes("DEBUG")
            ? "text-green-400"
            : log.type.includes("ERROR")
            ? "text-red-400"
            : "text-yellow-400"
        }
      >
        {log.type}
      </span>

      {log.line && (
        <>
          <span className="text-gray-500"> | </span>
          <span className="text-purple-400">Line {log.line}</span>
        </>
      )}

      {log.message && (
        <>
          <span className="text-gray-500"> | </span>
          <span className="text-gray-200">{log.message}</span>
        </>
      )}
    </div>
  );

  return (
    <div className="bg-zinc-900 text-gray-100 p-4 rounded-lg font-mono text-sm overflow-y-auto">
      {testNumbers.map((testNumber) => {
        const testLogs =
          sections[testNumber]
            ?.map(parseLogLine)
            .filter((log) => log !== null) || [];

        return (
          <div key={testNumber} className="mb-4">
            <div
              className="flex items-center cursor-pointer p-2 bg-zinc-800 rounded-t hover:bg-gray-700 transition-colors"
              onClick={() =>
                setActiveTest(activeTest === testNumber ? null : testNumber)
              }
            >
              <div className="flex items-center space-x-2">
                <span className="text-sm font-semibold text-gray-300">
                  Test #{testNumber}
                </span>
                <span className="text-xs px-2 py-1 bg-gray-700 rounded-full text-gray-300">
                  {testLogs.length} log entries
                </span>
              </div>
              <span className="ml-auto text-gray-500">
                {activeTest === testNumber ? "▼" : "▶"}
              </span>
            </div>

            {activeTest === testNumber && (
              <div className="border-l border-r border-b border-gray-700 rounded-b p-2 bg-gray-900">
                {testLogs.map((log, logIndex) => (
                  <div key={logIndex}>{renderLogLine(log)}</div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

function TabbedResultPanel({ executionStatus, debugLogs }) {
  const { showSpinner } = useSelector((state) => state.courses);
  const [activeTestResult, setActiveTestResult] = useState("1");
  const [activeTab, setActiveTab] = useState("results");

  let executionResults = [];

  if (executionStatus?.data?.exceptionMessage) {
    try {
      executionResults = JSON.parse(
        executionStatus.data.exceptionMessage.replace(
          "TestResultException: ",
          ""
        )
      );
    } catch (e) {
      executionResults = executionStatus.data.exceptionMessage;
    }
  }

  const renderTabs = () => (
    <div className="tabs tabs-bordered mb-4">
      <button
        className={`tab tab-lg gap-2 text-sm ${
          activeTab === "results" ? "tab-active" : ""
        }`}
        onClick={() => setActiveTab("results")}
      >
        <MdOutput size={20} />
        Test Results
      </button>
      {showSpinner ? <Spinner /> : <></>}
      <button
        className={`tab tab-lg gap-2 text-sm ${
          activeTab === "debug" ? "tab-active" : ""
        }`}
        onClick={() => setActiveTab("debug")}
      >
        <FaTerminal size={16} />
        Debug Console
      </button>
    </div>
  );

  const renderResults = () => {
    if (executionResults.length && Array.isArray(executionResults)) {
      return (
        <div className="join join-vertical w-full">
          {executionResults?.map((result) => (
            <div
              key={result.order}
              onClick={() => setActiveTestResult(result.order)}
            >
              <div
                className={`collapse collapse-arrow join-item ${
                  activeTestResult === result.order ? "collapse-open" : ""
                }`}
              >
                <div className="collapse-title text-md font-xs flex items-center">
                  {/* <p className="pr-2">Scenario {result.order}</p> */}
                  <p className="pr-2">Scenario {result.order}</p>
                  {result.pass ? (
                    <FaCheckCircle color="green" />
                  ) : (
                    <MdCancel color="red" />
                  )}
                </div>

                <div className="collapse-content text-sm">
                  {result.scenario === "Problem" ? (
                    <div className="border-l-4 border-red-500 p-4 mb-4">
                      {/* <p className="text-red-300 font-medium">Problem:</p> */}
                      <p className="text-red-200">{result.message}</p>
                    </div>
                  ) : (
                    result.scenario && (
                      <>
                        <p className="pb-5 pl-4 underline">Scenario:</p>
                        <p className="pb-5 pl-4">{result.scenario}</p>
                      </>
                    )
                  )}
                  {result.message && result.scenario !== "Problem" && (
                    <div className="pl-4">
                      <p className="underline pb-2">Message:</p>
                      {result.message.split("\n").map((line, i) => (
                        <p className="pt-1" key={i}>
                          {line}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (executionStatus === "AUTH ERROR") {
      return (
        <>
          <h1 className="font-bold text-lg text-red-300 pb-1">Auth Error</h1>
          <h3 className="text-sm text-red-300">
            You've been logged out due to inactivity. Save your work locally and
            login to continue.
          </h3>
        </>
      );
    } else if (executionStatus?.data?.compileProblem) {
      return (
        <>
          <h1 className="font-bold text-lg">Error:</h1>
          <p className="text-sm">{executionStatus.data.compileProblem}</p>
        </>
      );
    } else if (executionStatus?.data?.exceptionMessage) {
      return (
        <>
          <h1 className="font-bold text-lg">Runtime Error:</h1>
          <p className="text-sm">{executionStatus.data.exceptionMessage}</p>
        </>
      );
    } else {
      return (
        <>
          <h1 className="font-bold text-lg">Result Panel</h1>
          <h3 className="text-sm">Hit the run button to test your code!</h3>
        </>
      );
    }
  };

  return (
    <div>
      {renderTabs()}
      {activeTab === "results" ? (
        renderResults()
      ) : (
        <DebugOutput logs={debugLogs} />
      )}
    </div>
  );
}

export default TabbedResultPanel;
