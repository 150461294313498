import { useEffect, useState } from "react";
import { getGlobalLeaderboard } from "../../features/competition/competitionSlice";
import { useSelector, useDispatch } from "react-redux";

import Contestant from "./Contestant";

function Leaderboard() {
  const { globalLeaderboard } = useSelector((state) => state.competitions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGlobalLeaderboard());
  }, [dispatch]);

  return (
    <div className="space-y-2 max-h-half overflow-y-auto">
      {globalLeaderboard?.map((contestant) => (
        <Contestant
          key={contestant._id}
          contestant={contestant}
          rank={contestant.rank}
        />
      ))}
    </div>
  );
}

export default Leaderboard;
