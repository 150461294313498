import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useSelector, useDispatch } from "react-redux";
import { getCourses } from "../../features/courses/courseSlice";
import CourseItem from "../Courses/CourseItem";
import CollectionTab from "./CollectionTab";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import Spinner from "../../shared/Spinner";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

function Courses() {
  const { courses, collections, openCollection, courseStatus, showSpinner } =
    useSelector((state) => state.courses);

  const dispatch = useDispatch();

  const location = useLocation();

  // Only fetch courses once when component mounts
  const courseType = location.pathname.split("/")[1];
  useEffect(() => {
    dispatch(getCourses(courseType));
  }, [dispatch, courseType]);

  const coursesToShow =
    courses?.filter((course) => {
      if (!openCollection?._id) return false;

      let extensibleCourse = { ...course };

      const courseStatusEntry = courseStatus.find(([id]) =>
        String(course._id).includes(id)
      );

      extensibleCourse.status = courseStatusEntry
        ? courseStatusEntry[1]
        : "Not Started";

      return course.collection === openCollection._id;
    }) || [];

  const coursesToShowStyle =
    coursesToShow.length === 1
      ? "lg:px-96 px-24 transition-opacity duration-300"
      : "course-container mx-0 p-6 grid md:grid-cols-3 lg:grid-cols-2 gap-4";

  if (!courses && showSpinner) {
    return <div className="h-screen">{showSpinner && <Spinner />}</div>;
  }

  return (
    <div className="min-h-screen bg-zinc-900">
      <Helmet>
        <title>Courses | Camp Apex</title>
        <meta
          name="description"
          content="Explore a wide range of Salesforce Apex courses and challenges at Camp Apex. Learn by doing with hands-on practice and real coding challenges."
        />
        <meta property="og:title" content="Courses | Camp Apex" />
        <meta
          property="og:description"
          content="Discover interactive Salesforce Apex courses designed to help you learn by doing."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://campapex.org${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://campapex.org/assets/hero6.png"
        />
        <link
          rel="canonical"
          href={`https://campapex.org${window.location.pathname}`}
        />
      </Helmet>
      <div className="flex flex-col justify-center items-center">
        <div>
          <h1 className="text-center font-semibold pt-9 lg:pt-16 pb-9">
            <CollectionTab collections={collections} />
          </h1>
        </div>
        <div className="lg:pt-7 lg:px-20 px-9">
          <ReactMarkdown
            className="justify-center lg:px-32 text-center lg:pb-12 pb-9"
            children={openCollection?.description}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        </div>

        <div className={coursesToShowStyle}>
          {coursesToShow.map((course, index) => (
            <div key={course._id || index} className="card p-3 lg:p-7">
              <CourseItem key={course._id} course={course} />
            </div>
          ))}
        </div>
      </div>

      {showSpinner && (
        <div className="h-screen">{showSpinner && <Spinner />}</div>
      )}

      <div className="text-center lg:pt-50 pt-24">
        <p>
          Have topic suggestions?
          <a
            className="text-sky-300 hover:underline"
            href="https://tally.so/r/mDzZVj"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Let me know!
          </a>
        </p>
      </div>
      <div className="text-center">
        <p>
          Want to write your own?
          <a
            className="text-sky-300 hover:underline"
            href="https://tally.so/r/mDzZVj"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Let's figure it out.
          </a>
        </p>
      </div>
    </div>
  );
}

export default Courses;
