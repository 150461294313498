import Editor from "@monaco-editor/react";
import { useSelector, useDispatch } from "react-redux";
import { useRef, useEffect } from "react";
import { setSolutions } from "../../features/courses/courseSlice";

function ContinuousCodeEditor({ lesson }) {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const { solutions } = useSelector((state) => state.courses);

  useEffect(() => {
    if (lesson) {
      const defaultCode =
        lesson?.progress[0]?.lastSubmission || lesson?.boilerPlateCode;
      if (!solutions[lesson._id]) {
        dispatch(setSolutions({ lessonId: lesson._id, value: defaultCode }));
      }
    }
  }, [lesson?._id, dispatch]);

  function handleEditorChange(value, event) {
    dispatch(setSolutions({ lessonId: lesson._id, value }));
  }

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
  }

  function handleEditorBeforeMount(monaco) {
    let theme = {
      base: "vs-dark",
      inherit: true,
      rules: [{ token: "type", foreground: "569cd6" }],
      colors: {},
    };
    monaco.editor.defineTheme("my-theme", theme);
  }

  if (!lesson) return null;

  return (
    <Editor
      className="inline-block"
      onChange={handleEditorChange}
      onMount={handleEditorDidMount}
      beforeMount={handleEditorBeforeMount}
      defaultLanguage="apex"
      theme="my-theme"
      value={solutions[lesson._id] || ""}
      options={{
        fontSize: "14px",
        minimap: { enabled: false },
        scrollbar: { alwaysConsumeMouseWheel: false },
        scrollBeyondLastLine: false,
      }}
    />
  );
}

export default ContinuousCodeEditor;
