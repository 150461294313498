import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CompetitionCardHolder from "./CompetitionCardHolder";
import Leaderboard from "./Leaderboard";
import { Helmet } from "react-helmet";
import EmbeddedForm from "../EmbeddedForm";
import { getAllContests } from "../../features/competition/competitionSlice";
import {
  Trophy,
  Calendar,
  Bot,
  Info,
  ChevronRight,
  Clock10,
} from "lucide-react";

function CompeteHome() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [activeSection, setActiveSection] = useState("competitions");

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllContests());
  }, [dispatch]);

  const { competitions } = useSelector((state) => state.competitions);
  const upcomingCompetitions = competitions?.filter(
    (comp) => new Date(comp.enddate) >= new Date()
  );
  const metaDescription = `Join our Salesforce Apex coding competitions! Challenge yourself with timed Salesforce Apex practice problems and compete globally with other developers.`;

  const HeroSection = () => (
    <div className="relative overflow-hidden bg-gradient-to-b from-black to-zinc-900">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_#ffffff33_0%,_transparent_65%)]" />
      <div className="container mx-auto px-6 py-16 relative">
        <div className="max-w-3xl mx-auto text-center">
          <div className="mb-6">
            <Trophy className="w-16 h-16 text-yellow-500 mx-auto" />
          </div>
          <h1 className="text-3xl md:text-3xl font-bold mb-6 bg-gradient-to-r from-white to-zinc-400 bg-clip-text text-transparent">
            Salesforce Coding Contests
          </h1>
          <p className="text-lg md:text-xl text-zinc-400 mb-8">
            Challenge yourself with timed Salesforce problems. Solve real-world
            Apex challenges to compete globally (and earn bragging rights).
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => setActiveSection("competitions")}
              className={`px-6 py-3 rounded-lg font-semibold transition-all flex items-center gap-2
                ${
                  activeSection === "competitions"
                    ? "bg-white text-black"
                    : "bg-zinc-800 text-white hover:bg-zinc-700"
                }`}
            >
              <Calendar className="w-5 h-5" />
              View Competitions
            </button>
            <button
              onClick={() => setActiveSection("info")}
              className={`px-6 py-3 rounded-lg font-semibold transition-all flex items-center gap-2
                ${
                  activeSection === "info"
                    ? "bg-white text-black"
                    : "bg-zinc-800 text-white hover:bg-zinc-700"
                }`}
            >
              <Info className="w-5 h-5" />
              How It Works
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const InfoSection = () => (
    <div className="bg-zinc-900 rounded-xl p-8">
      <h2 className="text-2xl font-bold mb-6">How It Works</h2>
      <div className="space-y-8">
        <div className="flex gap-4">
          <div className="flex-shrink-0 w-12 h-12 bg-zinc-800 rounded-full flex items-center justify-center">
            <ChevronRight className="w-6 h-6 text-green-400" />
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">Participation Steps</h3>
            <p className="text-zinc-400">
              Register for an upcoming competition by providing an email and
              display name, solve the challenges within the time limit, and
              submit your solutions. Each challenge is designed to test
              real-world Salesforce scenarios.
            </p>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex-shrink-0 w-12 h-12 bg-zinc-800 rounded-full flex items-center justify-center">
            <Trophy className="w-6 h-6 text-yellow-400" />
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">Rankings & Rewards</h3>
            <p className="text-zinc-400">
              You'll earn points based on your final placement. The faster you
              solve the challenges, the higher on the leaderboard you will be.
              The timer starts when the competition starts, not when you start.
              <br />
              <br />
              Top performers are featured on our global leaderboard and may
              receive special recognition and prizes.
            </p>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex-shrink-0 w-12 h-12 bg-zinc-800 rounded-full flex items-center justify-center">
            <Bot className="w-6 h-6 text-purple-400" />
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">Can I use AI?</h3>
            <p className="text-zinc-400">
              No, don't be a cheater and ruin this for everyone. Submissions
              will be evaluated to spot any potential instances of cheating and
              you may face a ban from the platform.
              <br />
            </p>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex-shrink-0 w-12 h-12 bg-zinc-800 rounded-full flex items-center justify-center">
            <Clock10 className="w-6 h-6 text-blue-400" />
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">
              How much time do I have?
            </h3>
            <p className="text-zinc-400">
              Each contest has it's own alloted timeslot. You must begin when
              the competition does for the best chance to land on the
              leaderboard. Once the competition time is over, you will no longer
              be able to view and submit solutions.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <div className="min-h-screen bg-black">
        <HeroSection />
        <div className="container mx-auto px-4 py-8">
          {activeSection === "info" ? (
            <InfoSection />
          ) : (
            <>
              <div className="space-y-8">
                <CompetitionCardHolder competitions={competitions} />
                <EmbeddedForm />
              </div>
              <div className="bg-zinc-900 rounded-xl p-6 mt-8">
                <div className="flex items-center gap-2 mb-6">
                  <Trophy className="text-yellow-500" size={24} />
                  <h2 className="text-2xl font-bold">Global Rankings</h2>
                </div>
                <Leaderboard />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Salesforce Coding Contests - Join Global Competitions</title>
        <meta name="description" content={metaDescription} />
        <meta
          property="og:title"
          content="Salesforce Coding Contests - Join Global Competitions"
        />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Salesforce Coding Contests - Join Global Competitions"
        />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="/compete" />
      </Helmet>

      <div className="min-h-screen bg-black">
        <HeroSection />
        <div className="container mx-auto px-6 py-12">
          {activeSection === "info" ? (
            <InfoSection />
          ) : (
            <>
              <div className="grid grid-cols-12 gap-8">
                <div className="hidden xl:block xl:col-span-3">
                  <div className="bg-zinc-900 rounded-xl p-6 sticky top-8">
                    <div className="flex items-center gap-2 mb-6">
                      <Trophy className="text-yellow-500" size={24} />
                      <h2 className="text-2xl font-bold">Global Rankings</h2>
                    </div>
                    <Leaderboard />
                  </div>
                </div>

                <div className="col-span-12 xl:col-span-9">
                  <div className="space-y-8">
                    <CompetitionCardHolder competitions={competitions} />
                    <EmbeddedForm
                      formId="d93390eca5"
                      subheading="Sign up to be notified of all upcoming contests"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CompeteHome;
