import { useSelector, useDispatch } from "react-redux";
import {
  executeLesson,
  executeReadingCompleted,
  semanticValidationException,
} from "../../features/courses/courseSlice";
import { Link } from "react-router-dom";
import { getUser } from "../../features/auth/authSlice";
import { useLocation } from "react-router-dom";

function SubmitButton({ user }) {
  const { solution, lesson } = useSelector((state) => state.courses);
  const location = useLocation();
  const isFlowIntoApex = location.pathname.includes("flow-into-apex");
  const hasSubmission = lesson[0]?.progress?.length > 0;

  let moduleType = "Run";
  if (lesson[0]?.editorlessChallenge) {
    moduleType = isFlowIntoApex ? "Submit" : "Run Tests";
  }

  const dispatch = useDispatch();

  let RunButton;
  if (!user?.toString() && lesson[0]?.challenge) {
    RunButton = (
      <Link
        className="btn btn-sm btn-xs btn-primary lg:lessonBtn lesson-btn-small"
        target="_blank"
        to={"/auth"}
      >
        Login to Run
      </Link>
    );
  } else if (
    !lesson[0]?.challenge &&
    !lesson[0].progress[0]?.status &&
    user?.toString()
  ) {
    RunButton = (
      <Link
        to=""
        onClick={handleReadingComplete}
        className="btn btn-sm btn-xs btn-primary lg:lessonBtn lesson-btn-small"
      >
        Mark as Read
      </Link>
    );
  } else if (lesson[0]?.challenge) {
    const buttons = [];

    // For flow-into-apex, only show submit button if there's a submission
    if (!isFlowIntoApex || (isFlowIntoApex && hasSubmission)) {
      buttons.push(
        isFlowIntoApex ? (
          <div
            key="submit-wrapper"
            className="tooltip tooltip-warning"
            data-tip="Ensure your Flow is deactivated. This will test your solution and update the challenge status."
          >
            <Link
              key="submit"
              to=""
              onClick={() => handleSubmit(false)}
              className="btn btn-sm btn-xs btn-primary lg:lessonBtn lesson-btn-small"
              disabled={!solution && !lesson[0].editorlessChallenge}
            >
              {moduleType}
            </Link>
          </div>
        ) : (
          <Link
            key="submit"
            to=""
            onClick={() => handleSubmit(false)}
            className="btn btn-sm btn-xs btn-primary lg:lessonBtn lesson-btn-small"
            disabled={!solution && !lesson[0].editorlessChallenge}
          >
            {moduleType}
          </Link>
        )
      );
    }

    // Show "Run Tests Only" button for flow-into-apex with editorless challenge
    if (isFlowIntoApex && lesson[0].editorlessChallenge) {
      buttons.push(
        <div
          className="tooltip"
          data-tip="Verify your functionality without affecting challenge progress"
        >
          <Link
            key="run-tests"
            to=""
            onClick={() => handleSubmit(true)}
            className="btn btn-sm btn-xs btn-secondary lg:lessonBtn lesson-btn-small ml-2"
            disabled={!solution && !lesson[0].editorlessChallenge}
          >
            Run Tests Only
          </Link>
        </div>
      );
    }

    RunButton = <div className="flex">{buttons}</div>;
  }

  function handleSubmit(runTestOnly = false) {
    dispatch(getUser());
    let validationError;
    if (
      !lesson[0].js &&
      !lesson[0].editorlessChallenge &&
      lesson[0].type !== "SOQL"
    ) {
      validationError = basicSemanticValidation(solution);
    }
    if (!validationError) {
      let body = {
        lessonId: lesson[0]._id,
        apexBody: solution.trim(),
        lessonType: lesson[0].type,
      };

      if (runTestOnly) {
        body.runTestOnly = true;
      }

      dispatch(executeLesson(body));
    } else {
      dispatch(semanticValidationException(validationError));
    }
  }

  function handleReadingComplete() {
    dispatch(getUser());
    let body = {
      lessonId: lesson[0]._id,
    };
    dispatch(executeReadingCompleted(body));
  }

  function basicSemanticValidation(apexBody) {
    apexBody = apexBody.trim();
    if (!apexBody.includes(";")) {
      return "Unable to compile. You might be missing a semicolon";
    }
    return null;
  }

  return <div>{RunButton}</div>;
}

export default SubmitButton;
