import axios from "axios";
const API_ALL_COMPS = "/api/compete/getAllContests";
const API_URL_COMPETE_BASE = "/api/compete/";

const getAllContests = async () => {
  let response;
  try {
    response = await axios.get(API_ALL_COMPS);
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const getAdventLeaderboard = async () => {
  let response;
  try {
    response = await axios.get(API_URL_COMPETE_BASE + "getAdventLeaderboard");
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const getGlobalLeaderboard = async () => {
  let response;
  try {
    response = await axios.get(API_URL_COMPETE_BASE + "getGlobalLeaderboard");
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const getPointsDistribution = async (type) => {
  let response;
  try {
    response = await axios.get(
      API_URL_COMPETE_BASE + "getPointsDistribution/" + type
    );
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const getContest = async (tag) => {
  let response;
  try {
    response = await axios.get(API_URL_COMPETE_BASE + "getContest/" + tag);
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const registerDisplayName = async (displayName, email) => {
  let response;
  try {
    response = await axios.post(`${API_URL_COMPETE_BASE}registerDisplayName`, {
      displayName,
      email,
    });
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const getStats = async () => {
  let response;
  try {
    response = await axios.get(API_URL_COMPETE_BASE + "getStats/");
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const register = async (contestId, accessCode) => {
  let response;
  try {
    const options = {
      method: "POST",
      data: { contestId: contestId, accessCode: accessCode },
      url: API_URL_COMPETE_BASE + "register/",
    };

    response = await axios(options);
  } catch (err) {
    console.log(err);
    throw err;
  }

  return response?.data;
};

const logAction = async (action) => {
  const options = {
    method: "POST",
    data: { action: action },
    url: API_URL_COMPETE_BASE + "logAction/",
  };

  let response = await axios(options);

  return response.data;
};

const getContestChallenge = async (challengeId) => {
  let response;
  try {
    response = await axios.get(
      API_URL_COMPETE_BASE + "getChallenge/" + challengeId
    );
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const getSubmissions = async (challengeId) => {
  let response;
  try {
    response = await axios.get(
      API_URL_COMPETE_BASE + "getSubmissions/" + challengeId
    );
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const runCode = async (payload) => {
  const options = {
    method: "POST",
    data: payload,
    url: API_URL_COMPETE_BASE + "submit/",
  };

  let response = await axios(options);

  return response.data;
};

const runCodeForPlayground = async (payload) => {
  const options = {
    method: "POST",
    data: payload,
    url: API_URL_COMPETE_BASE + "submitPlayground/",
  };

  let response = await axios(options);

  return response.data;
};

const executeCodeReset = async (challengeId) => {
  const options = {
    method: "POST",
    url: API_URL_COMPETE_BASE + "executeCodeReset/" + challengeId,
  };

  let response = await axios(options);

  return response.data;
};

const getAdventContests = async () => {
  try {
    const response = await axios.get(`${API_URL_COMPETE_BASE}advent-contests`);
    return response.data;
  } catch (err) {
    console.error("Error fetching advent contests:", err);
    throw err;
  }
};

const getAdventChallenge = async (tag, slug) => {
  let response;
  try {
    response = await axios.get(
      `${API_URL_COMPETE_BASE}getAdventChallenge/${tag}/${slug}`
    );
  } catch (err) {
    console.log(err);
  }
  return response.data;
};

const competitionService = {
  getAllContests,
  getGlobalLeaderboard,
  getPointsDistribution,
  getContest,
  registerDisplayName,
  getStats,
  register,
  getContestChallenge,
  runCode,
  getSubmissions,
  executeCodeReset,
  logAction,
  runCodeForPlayground,
  getAdventContests,
  getAdventChallenge,
  getAdventLeaderboard,
};

export default competitionService;
