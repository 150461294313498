import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import {
  registerDisplayName,
  clearMessages,
  getStats,
} from "../../features/competition/competitionSlice";
import { useState, useEffect } from "react";
import { FaMedal, FaTools, FaCode, FaPeopleCarry } from "react-icons/fa";
import { GiMailedFist } from "react-icons/gi";
import { ChevronDown, ChevronUp } from "lucide-react";
import Spinner from "../../shared/Spinner";
import SenjaFeedbackCollector from "./SenjaFeedbackCollector";

function SalesforceLogout({ user }) {
  const { registerDisplayNameMessage, userStats, showSpinner, updateSuccess } =
    useSelector((state) => state.competitions);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [submitError, setSubmitError] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isOpen, setIsOpen] = useState(!userStats?.userInfo?.email);

  useEffect(() => {
    dispatch(getStats());
  }, [dispatch, submitError]);

  useEffect(() => {
    if (userStats?.userInfo) {
      setDisplayName(userStats.userInfo.displayName || "");
      setEmail(userStats.userInfo.email || "");
    }
  }, [userStats]);

  useEffect(() => {
    if (updateSuccess) {
      const timer = setTimeout(() => {
        dispatch(clearMessages());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [updateSuccess]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitError("");
    setEmailError("");

    if (!displayName) {
      setSubmitError("Please enter a display name.");
      return;
    }

    if (!email) {
      setEmailError("Please enter an email address.");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    dispatch(registerDisplayName({ displayName, email }));
  };

  let placeholderDisplayName =
    userStats?.userInfo?.displayName || "SyntaxSlingerCodey";
  let registerText = userStats?.userInfo?.displayName
    ? "Your Display Name & Email:"
    : "Choose your Display Name & add your Email:";

  return (
    <div className="bg-zinc-900 text-white min-h-screen p-4">
      {showSpinner && <Spinner />}

      {/* Header Section */}
      <div className="max-w-7xl mx-auto mb-6 pt-8 sm:pt-16 ">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between bg-zinc-800 p-4 rounded-lg gap-2 sm:gap-4">
          <div className="flex items-center space-x-4">
            <FaMedal color="gold" size={32} />
            <h1 className="text-xl font-bold">
              {userStats?.userInfo?.displayName || "Welcome"}
            </h1>
          </div>
          <a
            href={`${user.toString()}`}
            className="text-sm sm:text-base text-primary hover:underline break-all"
            target="_blank"
            rel="noopener noreferrer"
          >
            {user.toString()}
          </a>
        </div>
      </div>

      {/* Registration Form */}
      <div className="bg-zinc-800 rounded-lg max-w-7xl mx-auto mb-8">
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="p-4 flex rounded-lg items-center justify-between cursor-pointer hover:bg-zinc-700 transition-colors duration-200"
        >
          <span className="text-base">Competition Registration</span>
          {isOpen ? (
            <ChevronUp className="w-5 h-5" />
          ) : (
            <ChevronDown className="w-5 h-5" />
          )}
        </div>

        {isOpen && (
          <div className="p-6">
            <p className="mb-4 text-center">{registerText}</p>
            <div className="space-y-4">
              <input
                type="text"
                placeholder={placeholderDisplayName}
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                className="input input-bordered w-full bg-zinc-900"
              />
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                className="input input-bordered w-full bg-zinc-900"
              />
              <div className="flex items-center gap-4">
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary w-full"
                >
                  {userStats?.userInfo?.displayName
                    ? "Update Info"
                    : "Set Info"}
                </button>
                {/* <p className="text-sm text-gray-300">
                  By providing your email address, you acknowledge and agree
                  that event sponsors may receive your email. These sponsors may
                  contact you regarding professional employment opportunities.
                </p> */}
              </div>
            </div>
            {updateSuccess && (
              <div className="mt-5 text-green-400 transition-opacity duration-500">
                {updateSuccess}
              </div>
            )}
            {(registerDisplayNameMessage || emailError || submitError) && (
              <div className="mt-4 text-red-400">
                {submitError || registerDisplayNameMessage || emailError}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Main Content Grid */}
      <div className="max-w-7xl mx-auto grid grid-cols-1  md:grid-cols-3 gap-6 h-full">
        {/* Stats and Registration Column */}
        <div className="md:col-span-1  h-full ">
          {/* User Stats Card */}
          <div className="bg-zinc-800 p-6 rounded-lg  h-full">
            <div className="space-y-4 pt-0 md:pt-16">
              <StatsRow
                label="Lessons Completed"
                value={userStats?.completedProgressCount}
              />
              <StatsRow
                label="Competition Participation"
                value={userStats?.contestantCount}
              />
              <StatsRow label="Global Ranking" value={userStats?.userRank} />
              <StatsRow label="Competition Points" value={userStats?.points} />
            </div>
          </div>
        </div>

        {/* Features Grid */}
        <div className="md:col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-6">
          <CategoryCard
            icon={<FaCode size={32} />}
            title="Interactive Lessons"
            description="Apex, SOQL, & OOP. Bite-sized lessons covering the fundamentals. Each paired with a challenge to solve."
            onClick={() => navigate("/courses")}
          />
          <CategoryCard
            icon={<FaTools size={32} />}
            title="Projects"
            description="Build a custom Salesforce application from scratch. Camp Apex is your product manager and QA team. Implement the project's user stories. Will you choose clicks or code?"
            onClick={() => navigate("/projects")}
          />
          <CategoryCard
            icon={<GiMailedFist size={32} />}
            title="Competitions"
            description="Think you're a pretty good developer? Prove it. Time is ticking, compete against the world for a place on the Global Top 25. Competitions run monthly."
            onClick={() => navigate("/compete")}
          />
          <CategoryCard
            icon={<FaPeopleCarry size={32} />}
            title="Practice"
            description="Get more hands-on practice writing Apex code"
            onClick={() => navigate("/practice/flow-into-apex")}
          />
        </div>

        {/* Footer Section */}
        <div className="md:col-span-3 space-y-6">
          <div className="bg-zinc-800 p-6 rounded-lg">
            <div className="space-y-4">
              <p>
                Join the{" "}
                <a
                  className="text-primary hover:underline"
                  href="https://join.slack.com/t/campapexorg/shared_invite/zt-1yc61mwed-5RnC2pu7iITZzGZway1Srw"
                  target="_blank"
                >
                  Slack
                </a>{" "}
                community for support & live events.
              </p>
              <p>
                <a
                  className="text-primary hover:underline"
                  href="https://www.buymeacoffee.com/CampApexDotOrg"
                  target="_blank"
                >
                  Buy me a coffee
                </a>
                ; fuel my late-night working sessions & cover server costs.
              </p>
              <p>
                Help me spread the word! Share your success with other
                Trailblazers!
              </p>
            </div>
          </div>
          <SenjaFeedbackCollector />
        </div>
      </div>
    </div>
  );
}

// Utility Components
function StatsRow({ label, value }) {
  return (
    <div className="flex justify-between items-center">
      <span>{label}:</span>
      <span className="font-medium">{value}</span>
    </div>
  );
}

function CategoryCard({ icon, title, description, onClick }) {
  return (
    <div
      className="bg-zinc-800 p-6 rounded-lg cursor-pointer hover:bg-zinc-700 transition-colors duration-200"
      onClick={onClick}
    >
      <div className="flex items-center space-x-3 mb-4">
        {icon}
        <h3 className="text-lg font-semibold">{title}</h3>
      </div>
      <p className="text-sm text-zinc-300">{description}</p>
    </div>
  );
}

export default SalesforceLogout;
