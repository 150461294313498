import React from "react";
import { useSelector } from "react-redux";
import CompetitionCard from "./CompetitionCard";
import Spinner from "../../shared/Spinner";
import { Calendar, History } from "lucide-react";

function CompetitionCardHolder({ competitions }) {
  const { showSpinner } = useSelector((state) => state.competitions);
  const now = new Date();

  const pastCompetitions = competitions?.filter(
    (comp) => new Date(comp.enddate) < now
  );
  const upcomingCompetitions = competitions?.filter(
    (comp) => new Date(comp.enddate) >= now
  );

  const hasMultipleUpcoming = upcomingCompetitions?.length > 1;
  const hasPastCompetitions = pastCompetitions?.length > 0;

  return (
    <>
      {showSpinner && <Spinner />}
      <div className="space-y-6">
        {/* Featured Competition */}
        {upcomingCompetitions?.[0] && (
          <div className="bg-gradient-to-br from-zinc-900 to-zinc-800 rounded-xl p-1">
            <div className="bg-gradient-to-br from-zinc-900 via-zinc-900 to-zinc-800 rounded-xl p-6">
              <div className="flex items-center gap-2 mb-4">
                <div className="bg-yellow-500/10 text-yellow-500 px-3 py-1 rounded-full text-sm font-medium">
                  Next Competition - Register Now
                </div>
              </div>
              <CompetitionCard
                competition={upcomingCompetitions[0]}
                featured={true}
              />
            </div>
          </div>
        )}

        {/* Additional Competitions Sections */}
        <div className="bg-zinc-900 rounded-xl p-6">
          <div className="space-y-8">
            {/* Additional Upcoming Section */}
            {hasMultipleUpcoming && (
              <div>
                <div className="flex items-center gap-2 mb-6">
                  <Calendar size={20} />
                  <span className="font-medium">Additional Upcoming</span>
                  <span className="ml-2 bg-zinc-800 text-white px-2 py-0.5 rounded-full text-sm">
                    {upcomingCompetitions.length - 1}
                  </span>
                </div>
                <div className="grid gap-6">
                  {upcomingCompetitions.slice(1).map((comp) => (
                    <CompetitionCard key={comp._id} competition={comp} />
                  ))}
                </div>
              </div>
            )}

            {/* Past Competitions Section */}
            {hasPastCompetitions && (
              <div>
                <div className="flex items-center gap-2 mb-6">
                  <History size={20} />
                  <span className="font-medium">Past</span>
                  <span className="ml-2 bg-zinc-800 text-white px-2 py-0.5 rounded-full text-sm">
                    {pastCompetitions.length}
                  </span>
                </div>
                <div className="grid gap-6">
                  {pastCompetitions.map((comp) => (
                    <CompetitionCard key={comp._id} competition={comp} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CompetitionCardHolder;
