import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useEffect } from "react";
import { Helmet } from "react-helmet"; // Add this import
import LessonStatus from "./LessonStatus";
import LessonChallenge from "./LessonChallenge";
import InstallPackage from "./InstallPackage";
import { useSelector } from "react-redux";

function LessonMarkdown({ lesson }) {
  useEffect(() => {
    if (lesson[0]?.progress[0]?.status !== "In Progress") {
      var lessonPanelDiv = document.getElementById("lesson-panel");
      lessonPanelDiv.scrollTop = 0;
    }
  }, [lesson]);

  const { user } = useSelector((state) => state.auth);
  const lessonData = lesson[0];
  const lessonBody = lessonData?.lesson;
  const challengeBody = lessonData?.challenge;

  // Extract first paragraph for meta description
  const getDescription = (content) => {
    if (!content) return "";
    const firstParagraph = content.split("\n")[0].substring(0, 160);
    return firstParagraph.replace(/[#*`]/g, ""); // Remove markdown characters
  };

  return (
    <>
      <Helmet>
        <title>{`${lessonData?.name || "Lesson"} | Camp Apex`}</title>
        <meta name="description" content={getDescription(lessonBody)} />
        <meta
          property="og:title"
          content={`${lessonData?.name || "Lesson"} | Camp Apex`}
        />
        <meta property="og:description" content={getDescription(lessonBody)} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
        {lessonData?.tags?.map((tag) => (
          <meta key={tag} property="article:tag" content={tag} />
        ))}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            headline: lessonData?.name,
            description: getDescription(lessonBody),
            author: {
              "@type": "Organization",
              name: "Camp Apex",
            },
            dateModified: lessonData?.updatedAt,
            datePublished: lessonData?.createdAt,
          })}
        </script>
      </Helmet>

      {challengeBody ? (
        <div
          className="bg-zinc-900 text-slate-100 px-6 overflow-y-auto"
          id="lesson-panel"
          role="main"
          aria-label={`Lesson: ${lessonData?.name}`}
        >
          <LessonStatus lesson={lesson} />
          <article>
            {/* <h1 className="text-2xl font-bold mb-4">{lessonData?.name}</h1> */}
            <ReactMarkdown
              children={lessonBody ? lessonBody : challengeBody}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, "")}
                      style={vscDarkPlus}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            />
          </article>
          {lessonBody && <LessonChallenge challengeBody={challengeBody} />}
        </div>
      ) : (
        <div className="lg:max-h-screen">
          <article
            className="lg:px-[35em] bg-zinc-900 text-slate-100 grid grid-cols-1"
            id="lesson-panel"
          >
            <h1 className="font-semibold text-2xl text-center pt-4 pb-8">
              {lessonData?.name}
              {lessonData?.progress[0]?.status ? (
                <div className="mt-2">
                  <span className="indicator-item badge badge-success">
                    Completed
                  </span>
                </div>
              ) : (
                <div className="mt-2">
                  <span className="indicator-item badge badge-error">
                    Reading Only
                  </span>
                </div>
              )}
            </h1>
            <ReactMarkdown
              children={lessonBody}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || "");
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, "")}
                      style={vscDarkPlus}
                      language={match[1]}
                      PreTag="div"
                      {...props}
                    />
                  ) : (
                    <code className={className} {...props}>
                      {children}
                    </code>
                  );
                },
              }}
            />
          </article>
          <InstallPackage user={user} lesson={lesson} />
        </div>
      )}
    </>
  );
}

export default LessonMarkdown;
