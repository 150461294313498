import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContinuousCodeEditor from "./ContinuousCodeEditor";
import TabbedResultPanel from "./TabbedResultPanel";
import { Link } from "react-router-dom";
import { ChevronDown, Play, AlertCircle, Check } from "lucide-react";
import { executeLesson } from "../../features/courses/courseSlice";
import Header from "../Practice/Header";
import usePracticeData from "../../../hooks/usePracticeDataFromRole";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const ContinuousPractice = () => {
  const { selectedRole, lessonList, handleRoleChange, courseDetails } =
    usePracticeData();
  const { solutions } = useSelector((state) => state.courses);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [selectedLesson, setSelectedLesson] = useState(null);
  const [executionStatusMap, setExecutionStatusMap] = useState({});
  const [debugLogsMap, setDebugLogsMap] = useState({});

  const practiceData = {
    data: [
      {
        slug: "variables",
        type: "Variables",
        description: courseDetails.description,
      },
      { slug: "flow-into-apex", type: "Flow Into Apex" },
    ],
  };

  useEffect(() => {
    if (lessonList?.length > 0) {
      setSelectedLesson(lessonList[0]);
      const statusMap = lessonList.reduce((map, lesson) => {
        if (lesson.progress?.[0]) {
          map[lesson._id] = {
            data: { success: lesson.progress[0].status === "Completed" },
          };
        }
        return map;
      }, {});
      setExecutionStatusMap(statusMap);
    }
  }, [lessonList]);

  const handleRunCode = async (lesson) => {
    try {
      const lessonSolution = solutions[lesson._id];
      if (!lessonSolution) return;

      const result = await dispatch(
        executeLesson({
          lessonId: lesson._id,
          apexBody: lessonSolution.trim(),
          lessonType: "continuous",
        })
      ).unwrap();

      setExecutionStatusMap((prev) => ({
        ...prev,
        [lesson._id]: result,
      }));

      setDebugLogsMap((prev) => ({
        ...prev,
        [lesson._id]: result.debugLogs,
      }));
    } catch (error) {
      console.error("Error running code:", error);
      setExecutionStatusMap((prev) => ({
        ...prev,
        [lesson._id]: {
          data: {
            success: false,
            compileProblem: "An error occurred while running the code",
          },
        },
      }));
    }
  };

  return (
    <div className="bg-zinc-900 ">
      <Header
        initialRole={""}
        selectedRole={selectedRole}
        practiceData={practiceData}
        handleRoleChange={handleRoleChange}
      />

      <div className="flex h-[calc(100vh-64px)] pt-9">
        {/* Left Sidebar */}
        <div className="w-72 border-r border-zinc-800 overflow-y-auto">
          {lessonList?.map((lesson) => (
            <button
              key={lesson._id}
              onClick={() => setSelectedLesson(lesson)}
              className={`w-full flex items-center p-4 text-left hover:bg-zinc-800/50 transition-colors border-b border-zinc-700/50 ${
                selectedLesson?._id === lesson._id ? "bg-zinc-800/70" : ""
              }`}
            >
              <div className="flex items-center space-x-3">
                <div className="flex items-center justify-center w-8 h-8 rounded-full bg-zinc-800 text-zinc-300">
                  {lesson.order + 1}
                </div>
                <h2
                  className={`text-lg font-medium ${
                    executionStatusMap[lesson._id]?.data?.success
                      ? "text-emerald-300"
                      : "text-zinc-200"
                  }`}
                >
                  {lesson.name}
                </h2>
              </div>
            </button>
          ))}
        </div>

        {/* Main Content */}
        {selectedLesson && (
          <div className="flex-1 p-6 overflow-y-auto">
            <div className="space-y-4">
              <div className="prose prose-invert max-w-none">
                <div className="p-4 bg-zinc-800/30 rounded-lg border border-zinc-700/50">
                  <ReactMarkdown
                    className="text-left "
                    children={selectedLesson.lesson}
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="bg-zinc-800/30 rounded-lg overflow-hidden ring-1 ring-zinc-700/50">
                  <div className="flex items-center justify-between p-3 bg-zinc-800/50 border-b border-zinc-700/50">
                    <span className="text-sm text-zinc-400">Editor</span>
                  </div>
                  <div className="h-96">
                    <ContinuousCodeEditor lesson={selectedLesson} />
                  </div>
                </div>

                <div className="bg-zinc-800/30 rounded-lg overflow-hidden ring-1 ring-zinc-700/50">
                  <div className="flex items-center justify-between p-3 bg-zinc-800/50 border-b border-zinc-700/50">
                    <span className="text-sm text-zinc-300">Test Results</span>
                    {executionStatusMap[selectedLesson._id]?.data?.success ? (
                      <div className="flex items-center text-green-400">
                        <Check size={16} className="mr-1" />
                        <span className="text-sm">Passed</span>
                      </div>
                    ) : executionStatusMap[selectedLesson._id] ? (
                      <div className="flex items-center text-red-400">
                        <AlertCircle size={16} className="mr-1" />
                        <span className="text-sm">Failed</span>
                      </div>
                    ) : null}
                  </div>
                  <div className="p-4">
                    <TabbedResultPanel
                      executionStatus={executionStatusMap[selectedLesson._id]}
                      debugLogs={debugLogsMap[selectedLesson._id]}
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-end pt-2">
                {!user?.toString() ? (
                  <Link
                    target="_blank"
                    to={"/auth"}
                    className="flex items-center px-4 py-2 bg-orange-600 hover:bg-orange-700 text-white rounded-md shadow-lg transition-colors"
                  >
                    <AlertCircle size={16} className="mr-2" />
                    Login To Run Tests
                  </Link>
                ) : (
                  <button
                    onClick={() => handleRunCode(selectedLesson)}
                    disabled={!solutions[selectedLesson._id]}
                    className={`flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md shadow-lg transition-colors ${
                      !solutions[selectedLesson._id]
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    <Play size={16} className="mr-2" />
                    Run Tests
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContinuousPractice;
