import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setOpenCollection } from "../../features/courses/courseSlice";

function CollectionItem({ collection }) {
  const { openCollection } = useSelector((state) => state.courses);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = openCollection?.name === collection?.name;

  const handleClick = (e) => {
    // For new tab
    if (e.button === 1 || (e.button === 0 && (e.ctrlKey || e.metaKey))) {
      e.preventDefault();
      const baseRoute = location.pathname.split("/")[1];
      const slug = collection.name.toLowerCase().replace(/\s+/g, "-");
      window.open(`/${baseRoute}/${slug}`, "_blank");
      return;
    }

    e.preventDefault();
    const baseRoute = location.pathname.split("/")[1];
    const slug = collection.name.toLowerCase().replace(/\s+/g, "-");
    navigate(`/${baseRoute}/${slug}`);
    dispatch(setOpenCollection(collection));
  };

  const baseRoute = location.pathname.split("/")[1];
  const slug = collection.name.toLowerCase().replace(/\s+/g, "-");
  const fullPath = `/${baseRoute}/${slug}`;

  return (
    <a
      className={`tab tab-bordered ${
        isActive
          ? "tab-active lg:text-lg text-xs !text-white"
          : "lg:text-lg text-xs"
      }`}
      onClick={handleClick}
      onMouseDown={handleClick}
      href={fullPath}
      role="tablist"
    >
      {collection?.name}
    </a>
  );
}

export default CollectionItem;
