import { FaCampground } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SiSalesforce } from "react-icons/si";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { logout } from "../features/auth/authSlice";
import PropTypes from "prop-types";
import SimpleNav from "./SimpleNav";

function Navbar({ title, user }) {
  const dispatch = useDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    dispatch(logout());
  };

  const { pathname } = useLocation();
  if (pathname.includes("lesson")) {
    return <SimpleNav title={title} user={user} />;
  }

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const hideNav = () => {
    const elem = document.activeElement;
    if (elem) elem.blur();
  };

  let showGlow = pathname !== "/" && pathname.includes("/auth") && user;

  return (
    <div className={`navbar bg-black relative ${showGlow ? "main-nav" : ""}`}>
      <div className="navbar-start lg:pl-8">
        <FaCampground className="inline pr-2 text-3xl" />
        <Link to="/" className="lg:text-lg font-bold align-middle">
          {title}
        </Link>
      </div>
      <div className="navbar-end lg:mr-10">
        {/* Hamburger Menu */}
        <div className="lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-xl hover:text-purple-300"
            aria-label="Toggle menu"
          >
            <GiHamburgerMenu />
          </button>
        </div>

        {/* Desktop Links */}
        <div className={`hidden lg:flex`}>
          <NavLinks />
          {user ? (
            <UserDropdown handleLogout={handleLogout} hideNav={hideNav} />
          ) : (
            <Link className="text-base" to="/auth">
              Login
            </Link>
          )}
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="absolute top-full right-0 bg-black text-white w-full z-50 p-4 shadow-lg lg:hidden">
            <NavLinks closeMenu={() => setIsMenuOpen(false)} />
            {user ? (
              <div className="mt-4">
                <button
                  onClick={handleLogout}
                  className="block w-full text-left py-4 hover:text-purple-300"
                >
                  Logout
                </button>
              </div>
            ) : (
              <Link
                to="/auth"
                onClick={() => setIsMenuOpen(false)}
                className="block w-full py-4 hover:text-purple-300"
              >
                Login
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const NavLinks = ({ closeMenu }) => (
  <>
    <Link
      to="/courses"
      onClick={closeMenu}
      className="block lg:inline text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300 py-4 lg:py-0"
    >
      Learn
    </Link>
    <Link
      to="/practice"
      onClick={closeMenu}
      className="block lg:inline text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300 py-4 lg:py-0"
    >
      Practice
    </Link>
    <Link
      to="/advent"
      onClick={closeMenu}
      className="block lg:inline text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300 py-4 lg:py-0"
    >
      Advent
    </Link>
    <Link
      to="/projects"
      onClick={closeMenu}
      className="block lg:inline text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300 py-4 lg:py-0"
    >
      Projects
    </Link>
    <Link
      to="/compete"
      onClick={closeMenu}
      className="block lg:inline text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300 py-4 lg:py-0"
    >
      Compete
    </Link>
    <Link
      to="/playground"
      onClick={closeMenu}
      className="block lg:inline text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300 py-4 lg:py-0"
    >
      Playground
    </Link>
    <Link
      to="/about"
      onClick={closeMenu}
      className="block lg:inline text-xs lg:text-base mr-2 lg:mr-5 hover:text-purple-300 py-4 lg:py-0"
    >
      About
    </Link>
  </>
);

const UserDropdown = ({ handleLogout, hideNav }) => (
  <div className="dropdown dropdown-end">
    <div tabIndex={0} role="button" className="mr-3">
      <SiSalesforce color="#009EDB" className="inline pr-2 text-3xl" />
    </div>
    <ul
      tabIndex={0}
      className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-black rounded-box w-52"
    >
      <li onClick={hideNav}>
        <Link className="text-base" to="/auth">
          Profile
        </Link>
      </li>
      <li onClick={handleLogout}>
        <button className="text-base">Logout</button>
      </li>
    </ul>
  </div>
);

Navbar.defaultProps = {
  title: "Camp Apex",
};

Navbar.propTypes = {
  title: PropTypes.string,
  user: PropTypes.object,
};

NavLinks.propTypes = {
  closeMenu: PropTypes.func,
};

UserDropdown.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  hideNav: PropTypes.func.isRequired,
};

export default Navbar;
