import React from "react";

function Contestant({
  contestant: { displayName, totalScore, totalCompleted },
  rank,
}) {
  return (
    <div className="bg-zinc-800/50 p-4 rounded-lg flex items-center justify-between hover:bg-zinc-800 transition-colors">
      <div className="flex items-center gap-4">
        <span className="text-2xl font-bold text-zinc-500">{rank}</span>
        <div>
          <h3 className="text-white font-bold">{displayName}</h3>
          <div className="flex gap-4 text-sm text-zinc-400">
            <span>{totalScore} pts</span>
            <span>{totalCompleted} completed</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contestant;
