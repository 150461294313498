import React from "react";
import { Helmet } from "react-helmet";

const About = () => {
  const changelogData = [
    {
      date: "December 2024",
      updates: ["Released Advent of Code"],
    },
    {
      date: "November 2024",
      updates: ["Released Flow Into Apex"],
    },
    {
      date: "May 2024",
      updates: ["Introduced SOQL Lessons"],
    },
    {
      date: "April 2024",
      updates: ["Introduced Coding Competitions"],
    },
    {
      date: "February 2024",
      updates: ["Introduced SOQL Challenges"],
    },
    {
      date: "January 2024",
      updates: ["Introduced Projects"],
    },
    {
      date: "March 2023 - December 2023",
      updates: ["Added remaining Apex Fundamentals & OOP content"],
    },
    {
      date: "March 2023",
      updates: [
        "Initial public release",
        "Introduction to Variables and Operators",
      ],
    },
  ];

  return (
    <div className="min-h-screen w-full bg-zinc-900">
      <Helmet>
        <title>About Camp Apex | Learn Apex with Hands-On Practice</title>
        <meta
          name="description"
          content="A free, hands-on learning platform for aspiring Salesforce developers to master Apex through practice and real-world challenges."
        />
      </Helmet>

      <div className="py-24 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-4xl font-bold mb-6 text-white">
            Hands-On Salesforce <br /> Development Experience
          </h1>
          <p className="text-xl text-purple-300 mb-8 max-w-2xl mx-auto">
            Everyone can learn how to code. Camp Apex is here to help you prove
            that to yourself and build your confidence.
          </p>
        </div>
      </div>

      <div className="py-10 bg-zinc-800">
        <div className="max-w-4xl mx-auto px-4">
          <div className="flex items-center gap-8 mb-12">
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-purple-300 ring-offset-2 ring-offset-zinc-800">
                <img
                  src="https://camp-apex.s3.us-east-2.amazonaws.com/Misc/1720718725997.jpeg"
                  alt="Saman"
                />
              </div>
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-white">Saman</h3>
              <p className="text-purple-300">
                Senior Software Engineer & Delivery Lead at Okta | Creator of
                CampApex.org
              </p>
            </div>
          </div>
          <p className="text-lg text-white mb-8">
            I'm{" "}
            <a
              className="text-purple-400 hover:text-purple-300"
              href="https://www.linkedin.com/in/saman-attar/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Saman
            </a>
            . I married my high-school sweetheart, Sheeva, in March 2024. We
            have 2 cats, Garlic & Olive. I love to{" "}
            <a
              className="text-purple-400 hover:text-purple-300"
              href="https://vsco.co/saman-attar/gallery"
              target="_blank"
              rel="noopener noreferrer"
            >
              cook
            </a>
            , bake bread, and climb.
          </p>
          <p className="text-lg text-white mb-8">
            I built this site in 2022 and released it in 2023. It took a lot
            longer than I thought to build and producing the content has taken
            even longer. I've learned a lot and making this site has brought me
            a lot of happiness and fulfilment. I hope it helps you reach your
            goals.
          </p>

          <p className="text-lg text-white mb-8">
            You can read more about{" "}
            <a
              href="https://www.salesforce.com/trailblazer-stories/saman-attar/"
              className="text-purple-400 hover:text-purple-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              my Trailblazer story.
            </a>
          </p>
        </div>
      </div>

      <div className="py-20">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-white">
            Why I Built This
          </h2>
          <div className="space-y-8">
            <p className="text-lg text-white">
              Honestly, I'm a little nuts for putting this much time and energy
              into a free resource. But, I believe the most authentic approach
              to becoming a developer is by getting hands-on with a keyboard and
              writing actual code. I also believe education should be accessible
              to everyone.
            </p>
            <p className="text-lg text-white">
              If you're investing your time to learn a new skill, I'd bet your
              time is better spent learning from experience. Writing real code
              as you learn is the way to do that.
            </p>
            <p className="text-lg text-white">
              With Camp Apex, coding lessons have been divided into manageable,
              bite-sized sections, each paired with an Apex coding challenge to
              solve within the site. These challenges are designed to reinforce
              your learning and progressively build upon one another.
            </p>
          </div>
        </div>
      </div>

      <div className="py-20">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12 text-white">
            Learning Experience
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-zinc-800 p-8 rounded-xl shadow-sm border border-zinc-700">
              <h3 className="text-xl font-semibold mb-4 text-white">
                Interactive Learning
              </h3>
              <p className="text-purple-300">
                Practice with hundreds of hands-on exercises, from basic syntax
                to advanced patterns. Write, test, and debug real Apex code
                right in your browser.
              </p>
            </div>
            <div className="bg-zinc-800 p-8 rounded-xl shadow-sm border border-zinc-700">
              <h3 className="text-xl font-semibold mb-4 text-white">
                Real-World Problems
              </h3>
              <p className="text-purple-300">
                Connect securely to your Salesforce org to execute your code in
                a real environment. Get immediate feedback and while getting
                real experience.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-20 bg-zinc-800">
        <div className="max-w-4xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-white">
            Platform Updates
          </h2>
          <div className="space-y-12">
            {changelogData.map((entry, index) => (
              <div key={index} className="border-l-2 border-purple-400 pl-6">
                <h3 className="text-xl font-semibold mb-4 text-white">
                  {entry.date}
                </h3>
                <ul className="space-y-3">
                  {entry.updates.map((update, idx) => (
                    <li key={idx} className="text-purple-300">
                      {update}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-20">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8 text-white">
            Support Camp Apex
          </h2>
          <p className="text-lg text-purple-300 mb-8">
            This platform is and will always be free. The best way to support is
            by using it, sharing it with others, and providing feedback to make
            it better.
          </p>
          {/* <a
            className="inline-block bg-purple-600 hover:bg-purple-500 text-white font-semibold px-8 py-3 rounded-lg transition-colors"
            href="https://www.buymeacoffee.com/CampApexDotOrg"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy me a coffee
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default About;
