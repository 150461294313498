import { useState, useEffect } from "react";
import CollectionItem from "./CollectionItem";
import { useSelector, useDispatch } from "react-redux";
import { setOpenCollection } from "../../features/courses/courseSlice";
import { useParams, useNavigate } from "react-router-dom";

const generateSlug = (name) => {
  return name?.toLowerCase().replace(/\s+/g, "-") || "";
};

const findCollectionBySlug = (collections, slug) => {
  if (!Array.isArray(collections) || !slug) return null;
  return collections.find(
    (collection) => generateSlug(collection.name) === slug
  );
};

function CollectionTab({ collections = [] }) {
  // Provide default empty array
  const dispatch = useDispatch();
  const { courseName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure collections is an array and has items
    if (!Array.isArray(collections) || !collections.length) return;

    if (courseName) {
      const matchingCollection = findCollectionBySlug(collections, courseName);

      if (matchingCollection) {
        dispatch(setOpenCollection(matchingCollection));
      } else {
        navigate("/course");
      }
    } else {
      dispatch(setOpenCollection(collections[0]));
    }
  }, [dispatch, collections, courseName, navigate]);

  const handleTabClick = (collection) => {
    if (!collection?.name) return;
    const slug = generateSlug(collection.name);
    navigate(`/course/${slug}`);
    dispatch(setOpenCollection(collection));
  };

  // Early return if collections is not an array
  if (!Array.isArray(collections)) {
    console.warn("Collections prop must be an array");
    return null;
  }

  // If collections is empty, you might want to show a loading state or message
  if (collections.length === 0) {
    return (
      <div className="flex justify-center">
        <p className="text-gray-500">No collections available</p>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="tabs grid md:grid-rows-1 md:grid-flow-col">
        {collections.map((collection, index) => (
          <div key={collection._id || index}>
            <CollectionItem
              collection={collection}
              onClick={() => handleTabClick(collection)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CollectionTab;
