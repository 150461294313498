import { useEffect, useRef } from "react";

function EmbeddedForm({ subheading, formId }) {
  const containerRef = useRef(null);

  useEffect(() => {
    const cleanup = () => {
      const existingScript = document.querySelector(
        `script[data-uid="${formId}"]`
      );
      if (existingScript && existingScript.parentNode) {
        existingScript.parentNode.removeChild(existingScript);
      }

      const existingForm = document.querySelector("form");
      if (existingForm && existingForm.parentNode) {
        existingForm.parentNode.removeChild(existingForm);
      }
    };

    cleanup();

    const script = document.createElement("script");
    script.src = `https://campapex.kit.com/${formId}/index.js`;
    script.async = true;
    script.setAttribute("data-uid", formId);

    containerRef.current.appendChild(script);

    return cleanup;
  }, [formId]);

  return (
    <>
      <style>
        {`
          .formkit-powered-by-convertkit {
            display: none !important;
          }
        `}
      </style>
      <div className="flex flex-col items-center text-center max-w-2xl mx-auto py-8">
        <h2 className="text-3xl font-bold mb-2">Get Notified</h2>
        {subheading && <p className="text-gray-600 -mb-8">{subheading}</p>}
        <div ref={containerRef} />
      </div>
    </>
  );
}

export default EmbeddedForm;
