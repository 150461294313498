import React from "react";

const RoleSelector = ({ matchedData, selectedCourse, setSelectedCourse }) => {
  const handleCourseSelect = (courseName) => {
    const formattedCourseName = courseName.replace(/\s+/g, "-").toLowerCase();
    setSelectedCourse(formattedCourseName);
  };

  return (
    <div className="flex justify-center pt-40">
      <div className="flex flex-wrap justify-center gap-4 max-w-3xl">
        {matchedData &&
          matchedData.courses
            .sort((a, b) => a.courseName.localeCompare(b.courseName))
            .map((course, index) => (
              <button
                key={index}
                onClick={() => handleCourseSelect(course.courseName)}
                className="group flex items-center gap-2 px-3 py-2 rounded-lg hover:bg-zinc-800 transition-colors mt-12 md:mt-8"
              >
                <span
                  className={`${
                    selectedCourse ===
                    course.courseName.replace(/\s+/g, "-").toLowerCase()
                      ? "text-purple-300 underline"
                      : "text-gray-400 group-hover:text-purple-300 transition-colors"
                  }`}
                >
                  {course.courseName}
                </span>
                <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-medium rounded-full bg-zinc-700 text-gray-200">
                  {course.challenges.length}
                </span>
              </button>
            ))}
      </div>
    </div>
  );
};

export default RoleSelector;
