import React, { useState, useEffect } from "react";
import { GiftIcon, HeartIcon, Menu } from "lucide-react";
import ChallengesSection from "./ChallengesSection";
import DisplayNameCheck from "./DisplayNameCheck";
import FAQ from "./FAQ";
import AdventLeaderboard from "./AdventLeaderboard";
import Characters from "./Characters";
import Welcome from "./Welcome";
import Intro from "./Intro";
import { getUser } from "../../features/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";

const Snowflake = ({ style }) => (
  <div className="absolute text-blue-100 opacity-60" style={style}>
    ❄
  </div>
);

const AuthorSection = () => (
  <div className="flex flex-col items-center mb-8">
    <div className="flex flex-wrap justify-center gap-4 px-4 text-center">
      {[
        {
          name: "Saman Attar",
          url: "https://www.linkedin.com/in/saman-attar/",
        },
        {
          name: "Warren Walters",
          url: "https://www.linkedin.com/in/walters954/",
        },
        {
          name: "Justin Smith",
          url: "https://www.linkedin.com/in/justinmsmith1/",
        },
        {
          name: "Kimberly Small",
          url: "https://www.linkedin.com/in/kimberlylsmall/",
        },
        {
          name: "Nick Frates",
          url: "https://www.linkedin.com/in/nick-frates-94241a130/",
        },
      ].map((author, index) => (
        <a
          key={index}
          href={author.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 hover:text-blue-300 transition-colors duration-300"
        >
          {author.name}
        </a>
      ))}
    </div>
  </div>
);

const CharitySection = () => (
  <div className="bg-blue-900/20 rounded-lg p-6 mx-4 md:mx-0">
    <div className="flex items-center justify-center space-x-2 mb-4">
      <HeartIcon className="text-red-400" size={24} />
      <h3 className="text-xl font-semibold text-blue-300">For a Good Cause</h3>
      <HeartIcon className="text-red-400" size={24} />
    </div>
    <p className="text-blue-200 mb-6 text-center text-sm md:text-base">
      We're raising money for charity! In the spirit of the holidays,{" "}
      <br className="hidden md:block" />
      please consider donating to a charity close to Saman's heart. Every $1 you
      donate becomes 3 meals <br className="hidden md:block" />
      for those in need. <br className="hidden md:block" />
      <br /> 100% of contributions will go directly to the charity.
    </p>
    <div className="flex flex-wrap justify-center gap-4">
      {["Minnie's Food Pantry"].map((charity, index) => (
        <a
          key={index}
          href="https://www.gofundme.com/f/camp-apex-minnies-food-pantry"
          target="_blank"
          rel="noreferrer"
          className="inline-flex items-center px-6 py-3 bg-orange-700 hover:bg-orange-800 text-white text-lg font-medium rounded-lg transition-colors duration-300"
        >
          <GiftIcon className="mr-2" size={20} />
          Donate To Minnie's Food Pantry
        </a>
      ))}
    </div>
  </div>
);

const SideNavigation = ({
  sections,
  activeSection,
  onSelect,
  isMobile,
  isMenuOpen,
  setIsMenuOpen,
}) => (
  <div
    className={`${isMobile ? "w-full" : "w-64"} bg-zinc-900/50 p-4 rounded-lg`}
  >
    {isMobile && (
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="w-full flex items-center justify-between p-4 bg-zinc-800 rounded-lg mb-2"
      >
        <span className="text-blue-300">Menu</span>
        <Menu className="text-blue-300" />
      </button>
    )}
    <div
      className={`flex flex-col space-y-2 ${
        isMobile && !isMenuOpen ? "hidden" : "block"
      }`}
    >
      {sections.map((section) => (
        <button
          key={section.id}
          onClick={() => {
            onSelect(section.id);
            if (isMobile) setIsMenuOpen(false);
          }}
          className={`p-4 text-left rounded-lg transition-colors duration-300 ${
            activeSection === section.id
              ? "bg-blue-600 text-white"
              : "bg-zinc-800 text-blue-300 hover:bg-zinc-700"
          }`}
        >
          {section.title}
        </button>
      ))}
    </div>
  </div>
);

const ContentPanel = ({ section, isMobile }) => (
  <div
    className={`flex-1 bg-zinc-900/50 rounded-lg p-6 ${
      isMobile ? "mt-4" : "ml-4"
    } overflow-y-auto ${isMobile ? "h-[50vh]" : "h-[61.5vh]"}`}
  >
    <div className="prose prose-invert max-w-none">{section?.content}</div>
  </div>
);

const AdventOfSalesforceDev = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    dispatch(getUser());
    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, [dispatch]);

  const { user } = useSelector((state) => state.auth);
  const [activeSection, setActiveSection] = useState("what-is-this");
  const [snowflakes, setSnowflakes] = useState([]);

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.async = true;
  //   script.src =
  //     "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Tkh58F";

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  useEffect(() => {
    const createSnowflake = () => ({
      id: Math.random(),
      left: `${Math.random() * 100}%`,
      size: Math.random() * 2,
      animationDuration: `${Math.random() * 15 + 20}s`,
    });

    const interval = setInterval(() => {
      setSnowflakes((prev) => [...prev.slice(-20), createSnowflake()]);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const sections = [
    {
      id: "what-is-this",
      title: "About",
      content: <Welcome />,
    },
    {
      id: "gingerbread-village",
      title: "Gingerbread Village",
      content: <Intro />,
    },
    {
      id: "characters",
      title: "Characters",
      content: <Characters />,
    },
    {
      id: "challenges",
      title: "Weekly Challenges",
      content: (
        <>
          {user && user[0] ? (
            <DisplayNameCheck>
              <ChallengesSection />
            </DisplayNameCheck>
          ) : (
            <ChallengesSection />
          )}
        </>
      ),
    },
    {
      id: "leaderboard",
      title: "Leaderboard",
      content: <AdventLeaderboard />,
    },
    {
      id: "faq",
      title: "FAQ",
      content: <FAQ />,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-zinc-900 to-zinc-800 flex flex-col items-center pt-12 overflow-hidden relative">
      {snowflakes.map((snowflake) => (
        <Snowflake
          key={snowflake.id}
          style={{
            left: snowflake.left,
            fontSize: `${snowflake.size}rem`,
            animation: `fall ${snowflake.animationDuration} linear infinite`,
          }}
        />
      ))}

      <div className="text-blue-500 mb-4 text-6xl transform rotate-45 animate-pulse">
        ✧
      </div>
      <h1 className="text-3xl md:text-5xl font-bold text-blue-400 mb-8 text-center px-4">
        Advent of Salesforce Development (2024)
      </h1>

      <div className="w-full max-w-4xl px-4">
        <AuthorSection />
      </div>
      <div className="w-screen pb-9">
        <CharitySection />
      </div>

      <div className="w-full max-w-7xl px-4 mb-8">
        <div className={`${isMobile ? "flex flex-col" : "flex"}`}>
          <SideNavigation
            sections={sections}
            activeSection={activeSection}
            onSelect={setActiveSection}
            isMobile={isMobile}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
          <ContentPanel
            section={sections.find((s) => s.id === activeSection)}
            isMobile={isMobile}
          />
        </div>
      </div>

      <style jsx>{`
        @keyframes fall {
          0% {
            transform: translateY(-10vh) rotate(0deg);
          }
          100% {
            transform: translateY(110vh) rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default AdventOfSalesforceDev;
