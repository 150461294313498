import React, { useState, useRef, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { FaPlay } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { runCodeForPlayground } from "../../features/competition/competitionSlice";
import { getUser } from "../../features/auth/authSlice";
import { Link } from "react-router-dom";

const DebugOutput = ({ logs, isLoading, compileProblem }) => {
  const [allLogs, setAllLogs] = useState([]);

  useEffect(() => {
    if (logs) {
      setAllLogs((prev) => [
        ...prev,
        {
          timestamp: new Date().toISOString(),
          content: logs,
        },
      ]);
    }
  }, [logs]);

  const parseLogLine = (line) => {
    const regex =
      /(\d{2}:\d{2}:\d{2}\.\d{2})\s*\(\d+\)\|([^|]+)\|(?:\[(\d+)\]\|)?([^|]*)(?:\|(.*))?/;
    const match = line.match(regex);
    if (!match) return null;
    return {
      timestamp: match[1],
      type: match[2],
      level: match[4],
      message: match[5],
    };
  };

  const processLogEntry = (logContent) => {
    return logContent
      .split("\n")
      .filter(
        (line) =>
          !line.includes("CODE_UNIT") &&
          !line.includes("EXECUTION_STARTED") &&
          !line.includes("EXECUTION_FINISHED") &&
          !line.includes("CAMP_APEX_TEST_NO") &&
          !line.includes("USER_INFO") &&
          line.trim()
      )
      .map(parseLogLine)
      .filter((log) => log !== null);
  };

  const renderLogLine = (log) => (
    <div className="leading-5 mb-1">
      <span className="text-blue-400">{log.timestamp}</span>
      <span className="text-gray-500"> | </span>
      <span
        className={
          log.type.includes("DEBUG")
            ? "text-green-400"
            : log.type.includes("ERROR")
            ? "text-red-400"
            : "text-yellow-400"
        }
      >
        {log.type}
      </span>
      {log.message && (
        <>
          <span className="text-gray-500"> | </span>
          <span className="text-gray-200">{log.message}</span>
        </>
      )}
    </div>
  );

  if (!allLogs.length && !isLoading && !compileProblem) {
    return (
      <div className="bg-zinc-900 text-gray-500 h-full font-mono text-sm p-4">
        Run your code to see debug logs here
      </div>
    );
  }

  return (
    <div className="bg-zinc-900 text-gray-100 h-full font-mono text-sm p-4 overflow-y-auto border-l border-zinc-800">
      {compileProblem && (
        <div className="mb-4 p-3 bg-red-900/30 border border-red-700 rounded text-red-300">
          <div className="font-semibold mb-1">Compilation Error:</div>
          <div>{compileProblem}</div>
        </div>
      )}
      {allLogs.map((entry, sessionIdx) => (
        <div key={sessionIdx} className="mb-6">
          <div className="text-xs text-gray-500 mb-2 border-b border-gray-800 pb-1">
            Execution {sessionIdx + 1} -{" "}
            {new Date(entry.timestamp).toLocaleTimeString()}
          </div>
          {processLogEntry(entry.content).map((log, lineIdx) => (
            <div key={`${sessionIdx}-${lineIdx}`}>{renderLogLine(log)}</div>
          ))}
        </div>
      ))}
      {isLoading && (
        <div className="flex items-center gap-2 text-gray-400">
          <div className="animate-spin h-4 w-4 border-2 border-blue-500 rounded-full border-t-transparent" />
          Executing...
        </div>
      )}
    </div>
  );
};

const Playground = () => {
  const [solution, setSolution] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const editorRef = useRef(null);
  const dispatch = useDispatch();

  const { debugLogs, compileProblem } = useSelector(
    (state) => state.competitions
  );
  const { user, fetchingUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (fetchingUser) {
      setShowAuthDialog(false);
    } else if (user && user[0]?.instanceUrl) {
      setShowAuthDialog(false);
    } else {
      setShowAuthDialog(true);
    }
  }, [user, fetchingUser]);

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
    setSolution("// Write your code here");
  };

  const handleEditorBeforeMount = (monaco) => {
    monaco.editor.defineTheme("modern-dark", {
      base: "vs-dark",
      inherit: true,
      rules: [{ token: "type", foreground: "569cd6" }],
      colors: {
        "editor.background": "#1e1e1e",
        "editor.foreground": "#d4d4d4",
        "editor.lineHighlightBackground": "#2a2a2a",
        "editorCursor.foreground": "#d4d4d4",
        "editor.selectionBackground": "#404040",
      },
    });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await dispatch(runCodeForPlayground({ apexBody: solution.trim() }));
    } catch (error) {
      console.error("Error executing code:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showAuthDialog && (
        <div className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-zinc-900 rounded-lg p-6 max-w-md mx-4 border border-zinc-800">
            <p className="text-gray-300 mb-6">
              Please log in to a Salesforce org before accessing this code
              playground.
            </p>
            <Link
              to="/auth"
              className="inline-block bg-purple-300 hover:bg-purple-400 text-white px-6 py-2 rounded-md transition-colors duration-200"
            >
              Connect to an Org
            </Link>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 mt-16">
        <h1 className="text-3xl font-bold text-center text-zinc-500 mb-8">
          Apex Compiler for Salesforce experiments
        </h1>
        <div className="h-[600px] bg-[#1e1e1e] flex flex-col rounded-lg overflow-hidden shadow-xl">
          <div className="flex-1 flex">
            <div className="w-1/2 border-r border-zinc-800">
              <Editor
                onChange={setSolution}
                onMount={handleEditorDidMount}
                beforeMount={handleEditorBeforeMount}
                defaultLanguage="apex"
                theme="modern-dark"
                defaultValue="// Write your code here"
                options={{
                  fontSize: 14,
                  minimap: { enabled: false },
                  scrollbar: { alwaysConsumeMouseWheel: false },
                  scrollBeyondLastLine: false,
                  padding: { top: 16 },
                  lineNumbers: "on",
                  glyphMargin: false,
                  folding: true,
                  lineDecorationsWidth: 0,
                  lineNumbersMinChars: 3,
                }}
              />
            </div>
            <div className="w-1/2">
              <DebugOutput
                logs={debugLogs}
                isLoading={isLoading}
                compileProblem={compileProblem}
              />
            </div>
          </div>
          <div className="border-t border-zinc-800 p-2 flex justify-end">
            <button
              onClick={handleSubmit}
              disabled={isLoading || !user[0]?.instanceUrl}
              className={`bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 rounded text-sm flex items-center gap-2 transition-colors ${
                isLoading || !user[0]?.instanceUrl
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              <FaPlay size={10} />
              {isLoading ? "Running..." : "Run"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Playground;
