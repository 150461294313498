import React from "react";
import CountdownTimer from "./CountdownTimer";
import { useNavigate } from "react-router-dom";
import { Calendar, MapPin, Trophy, Clock } from "lucide-react";

function CompetitionCard({ competition, featured = false }) {
  function convertUTCToLocal(utcDateString) {
    const date = new Date(utcDateString);
    return date.toLocaleTimeString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  const localDate = convertUTCToLocal(competition.startdate);
  const navigate = useNavigate();
  const isPast = new Date(competition.enddate) < new Date();
  const isActive =
    competition.startdate < new Date().toISOString() &&
    competition.enddate > new Date().toISOString();

  function handleClick() {
    navigate("/compete/" + competition?.tag);
  }

  if (featured) {
    return (
      <div
        onClick={handleClick}
        className="group relative bg-gradient-to-br from-zinc-800 to-zinc-900 rounded-xl p-6 hover:from-zinc-700 hover:to-zinc-800 transition-all cursor-pointer border border-zinc-700 hover:border-zinc-600"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/10 to-blue-500/10 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
        <div className="flex flex-col md:flex-row gap-6 items-start relative">
          <div className="w-full md:w-2/3">
            <div className="flex items-center gap-3 mb-3">
              <Trophy className="w-5 h-5 text-yellow-500" />
              <h3 className="text-2xl font-bold">{competition.name}</h3>
            </div>
            <p className="text-zinc-400 mb-4 ml-8">{competition.type}</p>
            <div className="flex items-center gap-4 text-sm text-zinc-400 ml-8">
              <div className="flex items-center gap-2">
                <MapPin size={16} />
                {competition.location}
              </div>
              <div className="flex items-center gap-2">
                <Calendar size={16} />
                {localDate}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 flex justify-end items-center">
            {!isPast &&
              (isActive ? (
                <div className="bg-gradient-to-r from-green-500/20 to-emerald-500/20 text-green-400 px-6 py-3 rounded-full font-medium inline-flex items-center gap-2">
                  <div className="w-2 h-2 rounded-full bg-green-400 animate-pulse" />
                  Live Now
                </div>
              ) : (
                <CountdownTimer
                  targetUtcDate={competition?.startdate}
                  showDays={true}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={handleClick}
      className="group bg-gradient-to-br from-zinc-800 to-zinc-900 rounded-lg hover:from-zinc-700 hover:to-zinc-800 transition-all cursor-pointer border border-zinc-800 hover:border-zinc-700"
    >
      <div className="p-4">
        <div className="flex justify-between items-start gap-4">
          <div className="flex-1">
            <div className="flex items-start gap-3 mb-2">
              {isActive ? (
                <div className="w-2 h-2 rounded-full bg-green-400 animate-pulse mt-2" />
              ) : (
                <div className="w-2 h-2 rounded-full bg-zinc-600 mt-2" />
              )}
              <div>
                <h3 className="font-semibold text-white group-hover:text-white/90">
                  {competition.name}
                </h3>
                <div className="text-sm text-zinc-400 mt-1">
                  {competition.type}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-3 ml-5 mt-3 text-sm text-zinc-500">
              <div className="flex items-center gap-1.5">
                <MapPin size={14} />
                {competition.location}
              </div>
              <div className="flex items-center gap-1.5">
                <Clock size={14} />
                {localDate}
              </div>
            </div>
          </div>

          {!isPast && !isActive && (
            <div className="bg-zinc-900/50 px-3 py-1.5 rounded-full text-xs text-zinc-400 whitespace-nowrap">
              Starts in{" "}
              {Math.ceil(
                (new Date(competition.startdate) - new Date()) /
                  (1000 * 60 * 60 * 24)
              )}
              d
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompetitionCard;
