import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import authService from "./authService";

const initialState = {
  user: [],
  isLoading: false,
  fetchingUser: true,
};

export const login = createAsyncThunk("login", async (thunkAPI) => {
  try {
    return await authService.login();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const customlogin = createAsyncThunk(
  "login",
  async (customURL, thunkAPI) => {
    try {
      return await authService.customlogin(customURL);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk("getUser", async (thunkAPI) => {
  try {
    return await authService.getUser();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const logout = createAsyncThunk("logout", async (thunkAPI) => {
  try {
    return await authService.logout();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

// NOTE: in cases of login or register pending or rejected then user will
// already be null so no need to set to null in these cases

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      //   .addCase(register.pending, (state) => {
      //     state.isLoading = true;
      //   })
      //   .addCase(register.fulfilled, (state, action) => {
      //     state.user = action.payload;
      //     state.isLoading = false;
      //   })
      //   .addCase(register.rejected, (state) => {
      //     state.isLoading = false;
      //   })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.fetchingUser = false;
      })
      .addCase(getUser.pending, (state, action) => {
        state.fetchingUser = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.user = [];
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default authSlice.reducer;
