import React from "react";
import { Link } from "react-router-dom";
import CountdownTimer from "./CountdownTimer";
import {
  resetCode,
  runCode,
  semanticValidationException,
  executionStatus,
  executeCodeReset,
} from "../../features/competition/competitionSlice";
import { VscDebugRestartFrame } from "react-icons/vsc";
import { useSelector, useDispatch } from "react-redux";

function CompetitionFooter({ challenge }) {
  const { solution, executionStatus } = useSelector(
    (state) => state.competitions
  );
  const dispatch = useDispatch();

  const [thisChallenge] = challenge;
  //console.log(thisChallenge);
  const {
    previousChallengeSlug,
    nextChallengeSlug,
    contestTag,
    tag,
    type,
    enddate,
  } = thisChallenge || {};
  let moduleType = "Problem";

  //console.log(thisChallenge);

  const handleReset = () => {
    dispatch(executeCodeReset(thisChallenge._id));
    setTimeout(function () {
      window.location.reload();
    }, 600);
  };

  function basicSemanticValidation(apexBody) {
    apexBody = apexBody?.trim();
    if (!apexBody || !apexBody.includes(";")) {
      return "Unable to compile. You might be missing a semicolon";
    }
    return null;
  }

  function execute() {
    try {
      let validationError;
      if (!thisChallenge.editorlessChallenge && thisChallenge.type !== "SOQL") {
        validationError = basicSemanticValidation(solution);
      }
      if (!validationError) {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        let body = {
          challengeId: thisChallenge._id,
          apexBody: solution != null ? solution.trim() : "",
          type: thisChallenge.type,
          timeZone,
        };
        dispatch(runCode(body));
      } else {
        dispatch(semanticValidationException(validationError));
      }
    } catch (e) {
      console.log(e);
    }
  }

  const renderLink = (to, text) => (
    <div className="col-span-1">
      <Link
        to={to}
        className="btn btn-sm btn-secondary lesson-btn-small lg:lessonBtn"
      >
        {text}
      </Link>
    </div>
  );

  const ResetButton = (
    <div className="tooltip" data-tip="Reset Challenge and Clear Code">
      <Link
        to=""
        onClick={handleReset}
        className="btn btn-sm btn-xs hover:bg-gray-900 bg-black lg:lessonBtn lesson-btn-small !w-16"
      >
        <VscDebugRestartFrame className="text-2xl" />
      </Link>
    </div>
  );

  const CompetitionButton = (tag) => (
    <div className="tooltip" data-tip="Head back to the main page">
      <Link to={`/compete/${tag.tag}`} className="text-primary hover:underline">
        {tag.tag}
      </Link>
    </div>
  );

  return (
    <div className="navbar bg-black">
      <div className="navbar-start">
        <div className="pr-2">
          {previousChallengeSlug && (
            <div>
              {renderLink(
                `/compete/${tag}/${previousChallengeSlug}`,
                `Last ${moduleType}`
              )}
            </div>
          )}
        </div>
        <div className="">
          {nextChallengeSlug && (
            <div>
              {renderLink(
                `/compete/${tag}/${nextChallengeSlug}`,
                `Next ${moduleType}`
              )}
            </div>
          )}
        </div>
      </div>

      <div className="navbar-center">
        <CompetitionButton tag={tag} />
      </div>

      <div className="navbar-end flex items-center">
        <ul className="menu menu-horizontal px-1">
          <Link
            to=""
            onClick={execute}
            className="btn btn-primary btn-sm btn-xs lg:lessonBtn lesson-btn-small mx-4"
          >
            Run Tests
          </Link>
          {thisChallenge.type !== "advent" && ResetButton}
          {thisChallenge.type !== "advent" && (
            <div className="text-xs flex items-center justify-end text-right w-48">
              <CountdownTimer targetUtcDate={enddate} showDays={false} />
              <div className="pl-1">remaining</div>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}

export default CompetitionFooter;
