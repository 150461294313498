import Editor from "@monaco-editor/react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import {
  setSolution,
  logAction,
} from "../../features/competition/competitionSlice";

function CodeEditor({ challenge }) {
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const { challengeProgress } = useSelector((state) => state.competitions);

  function handleEditorChange(value, event) {
    dispatch(setSolution(value));
  }

  function handleEditorDidMount(editor, monaco) {
    editorRef.current = editor;
    dispatch(
      setSolution(challengeProgress?.lastSubmission || challenge.defaultCode)
    );

    // const editorDomNode = editor.getDomNode();

    // if (editorDomNode) {
    //   editorDomNode.addEventListener(
    //     "paste",
    //     (event) => {
    //       event.stopPropagation();
    //       event.preventDefault();
    //       alert("Sorry! No pasting.");
    //       dispatch(logAction("Paste"));
    //     },
    //     true
    //   );
    // }
  }

  function handleEditorBeforeMount(monaco) {
    let theme = {
      base: "vs-dark",
      inherit: true,
      rules: [{ token: "type", foreground: "569cd6" }],
      colors: {
        // "editor.background": "#192544",
      },
    };
    monaco.editor.defineTheme("my-theme", theme);
  }

  let defaultCode = challengeProgress?.lastSubmission || challenge.defaultCode;

  return (
    <Editor
      className="inline-block"
      onChange={handleEditorChange}
      onMount={handleEditorDidMount}
      beforeMount={handleEditorBeforeMount}
      defaultLanguage="apex"
      theme="my-theme"
      defaultValue={defaultCode}
      options={{
        fontSize: "14px",
        minimap: {
          enabled: false,
        },
        scrollbar: {
          alwaysConsumeMouseWheel: false,
        },
        scrollBeyondLastLine: false,
      }}
    />
  );
}

export default CodeEditor;
