import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import usePracticeData from "../../../hooks/usePracticeData";
import Header from "./Header";
import RoleSelector from "./RoleSelector";
import CourseTable from "./CourseTable";
import { UserCircle } from "lucide-react";
import { Sparkles } from "lucide-react";
import PathCards from "./PathCards";

const PracticeList = () => {
  const { collection, role, course } = useParams();
  const navigate = useNavigate();

  const {
    initialRole,
    selectedRole,
    practiceData,
    showSpinner,
    selectedCourse,
    setSelectedCourse,
    handleRoleChange,
  } = usePracticeData();

  useEffect(() => {
    if (course) {
      setSelectedCourse(course);
    }
  }, [course, setSelectedCourse]);

  useEffect(() => {
    if (collection) {
      // console.log(`Handling role change: ${collection}`);
      handleRoleChange(collection);
    }
  }, [collection, handleRoleChange]);

  useEffect(() => {
    if (selectedCourse && role) {
      const formattedPath = `/practice/${role.toLowerCase()}/${selectedCourse}`
        .replace(/%20/g, "-")
        .replace(/\s+/g, "-");
      navigate(formattedPath, { replace: true });
    }
  }, [selectedCourse, role, navigate]);

  if (showSpinner) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  const dataArray = Array.isArray(practiceData.data) ? practiceData.data : [];
  const roles =
    practiceData.data?.map((item) => ({
      slug: item.slug,
      name: item.type || item.slug,
      description: item.description,
      shortDescription: item.shortDescription,
      author: item.author,
    })) || [];

  let matchedData = dataArray.find((data) =>
    data.type.toLowerCase().includes(selectedRole.toLowerCase())
  );

  matchedData = matchedData
    ? {
        ...matchedData,
        courses: [...matchedData.courses].sort((a, b) =>
          a.courseName.localeCompare(b.courseName)
        ),
      }
    : null;

  const handleCourseSelect = (courseName) => {
    setSelectedCourse(courseName);
  };

  return (
    <div className="min-h-screen cursor-default bg-zinc-900">
      {/* Main container with responsive padding */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-8">
        <Header
          initialRole={initialRole}
          selectedRole={selectedRole}
          practiceData={practiceData}
          handleRoleChange={handleRoleChange}
        />

        {/* Role selector section with responsive spacing */}
        <div className="mt-4 sm:mt-8">
          {initialRole !== "{Your Role}" && (
            <RoleSelector
              matchedData={matchedData}
              selectedCourse={selectedCourse}
              setSelectedCourse={handleCourseSelect}
            />
          )}
        </div>

        {/* Course tables section with responsive spacing */}
        <div className="mt-4 sm:mt-8 space-y-4 sm:space-y-6">
          {matchedData && (
            <div className="space-y-4">
              {selectedCourse
                ? matchedData.courses
                    .filter(
                      (course) =>
                        course.courseName.replace(/\s+/g, "-").toLowerCase() ===
                        selectedCourse
                    )
                    .map((course, courseIndex) => (
                      <CourseTable
                        key={courseIndex}
                        course={course}
                        selectedRole={selectedRole}
                      />
                    ))
                : matchedData.courses.map((course, courseIndex) => (
                    <CourseTable
                      key={courseIndex}
                      course={course}
                      selectedRole={selectedRole}
                      onSelect={() => handleCourseSelect(course.courseName)}
                    />
                  ))}
            </div>
          )}
          {!matchedData && (
            <div className="bg-gray-900">
              <PathCards
                matchedData={matchedData}
                roles={roles}
                handleRoleChange={handleRoleChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PracticeList;
