import React from "react";
import { useNavigate } from "react-router-dom";
import usePracticeData from "../../../hooks/usePracticeData";

const PathCards = () => {
  const navigate = useNavigate();
  const { practiceData, showSpinner } = usePracticeData();

  const roles =
    practiceData.data?.map((item) => ({
      slug: item.slug,
      name: item.type || item.slug,
      description: item.description,
      shortDescription: item.shortDescription,
      author: item.author,
    })) || [];

  if (showSpinner) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center pt-24 pb-12 bg-zinc-900">
      <div className="text-center space-y-6 z-10 mb-16">
        <h1 className="text-4xl md:text-5xl font-medium bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
          What should we practice today?
        </h1>

        <p className="text-zinc-300 text-lg max-w-xl mx-auto font-light">
          To learn, you need to practice. These are additional hands-on practice
          problems to help you get more experience.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto px-4 w-full">
        {roles.map((role) => (
          <button
            key={role.slug}
            onClick={() => navigate(`/practice/${role.slug.toLowerCase()}`)}
            className="group relative overflow-hidden bg-zinc-800/50 backdrop-blur p-8 rounded-2xl
                       transition-all duration-500 ease-out
                       hover:bg-zinc-800
                       hover:-translate-y-1
                       active:translate-y-0
                       border border-white/[0.08]
                       hover:border-white/[0.12]"
          >
            <div className="absolute inset-0 bg-gradient-to-b from-white/[0.07] to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />

            <h3 className="text-white font-medium text-xl mb-3">{role.name}</h3>
            <p className="text-zinc-300 text-sm leading-relaxed">
              {role.shortDescription}
            </p>
            <p className="text-zinc-500 text-sm mt-4">{role.author}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default PathCards;
