export const toUrlSlug = (name) => {
  return name.toLowerCase().replace(/\s+/g, "-");
};

export const fromUrlSlug = (slug) => {
  return slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
